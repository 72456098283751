import SignInLayout from 'containers/app/screens/SignIn/layout/SignInLayout'
import { ForgotPasswordComponent } from 'containers/app/screens/ForgotPassword/ForgotPassword/component/page/ForgotPasswordComponent'

const ForgotPassword = () => (
  <SignInLayout>
    <ForgotPasswordComponent />
  </SignInLayout>
)

export default ForgotPassword
