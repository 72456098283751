import Logo from 'assets/reactIcons/Logo'
import { Link } from 'react-router-dom'
import { Col, Row, Space, Typography } from 'antd'
import { ForgotPasswordForm } from 'containers/app/screens/ForgotPassword/ForgotPassword/component/forms/ForgotPasswordForm'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'
import { Text } from 'containers/shared/components/typography/Text'

export const ForgotPasswordComponent = () => (
  <Row justify='center'>
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <Col span={24}>
        <Row justify='center'>
          <Logo />
        </Row>
      </Col>
      <Col span={24}>
        <Space size={32}>
          <Typography.Text strong>Reset password</Typography.Text>
        </Space>
      </Col>
      <Col span={24}>
        <ForgotPasswordForm />
      </Col>
      <Col span={24}>
        <Row justify='center'>
          <Link to={APP_USERS_SIGN_IN_PATH}>
            <Text size='s'>
              <Typography.Text type='success'>Back to Login page</Typography.Text>
            </Text>
          </Link>
        </Row>
      </Col>
    </Space>
  </Row>
)
