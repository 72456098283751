/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button, Col, Input, Row, Space, Typography, notification } from 'antd'
import styles from 'containers/app/screens/ForgotPassword/NewPassword/component/forms/styles.module.scss'
import { inject, observer } from 'mobx-react'
import { MailOutlined } from '@ant-design/icons'
import { globalColors } from 'containers/app/data/colors'
import React from 'react'
import { ResetPasswordParams } from 'containers/shared/api/session.api'
import { useNavigate } from 'react-router-dom'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'

const validateEmail = (value: string) => {
  let error
  if (!value) {
    error = 'Email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'This email address is not registered, please try again'
  }
  return error
}

export const ForgotPasswordForm = inject('sessionStore')(
  observer(({ sessionStore }: { sessionStore?: any }) => {
    const navigate = useNavigate()
    return (
      <Row>
        <Col span={24}>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const res = await sessionStore.resetPassword(values)
                if (res.data?.error) {
                  notification.error({
                    message: "Such email doesn't exist, please try again",
                  })
                } else if (res.status === 200 && !res.data?.error) {
                  notification.success({
                    message: 'Confirmation email send to your mail',
                  })
                  setTimeout(() => {
                    navigate(APP_USERS_SIGN_IN_PATH)
                  }, 2000)
                }
                setSubmitting(false)
              } catch (e) {
                notification.error({
                  message: "Such email doesn't exist, please try again",
                })
                setSubmitting(false)
              }
            }}
          >
            {(props: FormikProps<ResetPasswordParams>) => (
              <Form>
                <Space direction='vertical' size={24} style={{ width: '100%' }}>
                  <Field name='email' validate={validateEmail}>
                    {({ field, meta }: FieldProps) => (
                      <Row>
                        <Col span={24}>
                          <Input
                            {...field}
                            size='large'
                            status={meta.touched && meta?.error && 'error'}
                            disabled={props.isSubmitting}
                            placeholder='Email: username@mail.com'
                            prefix={
                              <div className={styles.inputIconPad}>
                                <MailOutlined style={{ color: globalColors.purple }} />
                              </div>
                            }
                          />
                          <ErrorMessage name='email'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Button size='large' block type='primary' onClick={props.submitForm} disabled={props.isSubmitting}>
                    Send reset link
                  </Button>
                </Space>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    )
  }),
)
